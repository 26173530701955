import { useState, useContext } from "react";

const APISERVER = process.env.REACT_APP_API_SERVER;
const axios = require("axios");

export const HandleLogin = async (email, password) => {
  let data = { email, password };

  try {
    let response = await axios.post(APISERVER + "/login", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200 && response.data.token) {
      let jwt = response.data.token;
      let user = response.data.user;
      let isAdmin = response.data.isAdmin;

      localStorage.setItem("_token", jwt);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("isAdmin", isAdmin);
    }
  } catch (err) {
    // Handle Error Here
    throw err;
  }
};

export const CheckLogin = async () => {
  try {
    let response = await axios.get(APISERVER + "/welcome", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("_token"),
      },
    });
    return response.data;
  } catch (error) {
    // Handle Error Here
    throw error;
  }
};

export const HandleChangePassword = async (currentUser, values) => {
  try {
    let response = await axios.post(APISERVER + "/updatePassword", values, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("_token"),
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200 && response.data.token) {
      let jwt = response.data.token;
      let user = response.data.user;
      let isAdmin = response.data.isAdmin;

      if (currentUser.email === user.email) {
        localStorage.setItem("_token", jwt);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("isAdmin", isAdmin);
      }
    }
  } catch (error) {
    // Handle Error Here
    throw error.response.data.message;
  }
};
