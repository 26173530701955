import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CSpinner, CAlert, CModal, CModalBody } from "@coreui/react";

import useInterval from "hooks/useInterval";

import { AuthContext } from "contexts";
import { CheckLogin } from "services/auth";

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [location, setLocation] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [changePasswordModal, setChangePasswordModal] = useState(null);
  const [ChangeLocationModal, setChangeLocationModal] = useState(null);
  const [redirectModal, setRedirectModal] = useState(null);

  const history = useHistory();

  async function check_valid_session() {
    let token = localStorage.getItem("_token");
    if (token) {
      try {
        const res = await CheckLogin();
        setCurrentUser(res.user);
        setLocation(res.location);
        setIsAdmin(res.isAdmin);
      } catch (error) {
        if (!error.response) {
          // no connection
        } else {
          setRedirectModal(true);
          setTimeout(() => {
            localStorage.removeItem("_token");
            localStorage.removeItem("user");
            localStorage.removeItem("isAdmin");
            localStorage.removeItem("location");
            history.push("/login");
          }, 4000);
        }
      }
    }
  }

  useEffect(() => {
    check_valid_session();
  }, []);

  useInterval(() => {
    check_valid_session();
  }, 5000);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        location,
        changePasswordModal,
        setChangePasswordModal,
        ChangeLocationModal,
        setChangeLocationModal,
        isAdmin,
        setIsAdmin,
      }}
    >
      <CModal show={redirectModal} size="sm" centered closeOnBackdrop={false}>
        <CModalBody className="text-center">
          <CAlert color="danger" className="text-center">
            The session has expired! You will be redirected to Login ...
          </CAlert>
          <CSpinner color="primary" grow />
        </CModalBody>
      </CModal>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
