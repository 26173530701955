import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import AuthProvider from "providers/AuthProvider";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/Login"));
const Logout = React.lazy(() => import("./views/auth/Logout"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/logout"
              name="Login Page"
              render={(props) => <Logout {...props} />}
            />
            <AuthProvider>
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </AuthProvider>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
